import React, { useState, useEffect, useCallback, useRef } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { authenticatedApiCall } from '../../utils/authenticatedApiCall';
import { incrementImageGenerationCount, isImageGenerationLimited, isLoggedIn } from '../../utils/cookies';
import '../../css/ImageGenerator.css'
import useMessage from '../../hooks/useNotify';
import AdultContentWarning from './AdultContentWarning';
import { useDocumentHead } from '../../hooks/useDocumentHead'

import PromptSection from './ImageGenerator/PromptSection';
import SettingsPanel from './ImageGenerator/SettingsPanel';
import GeneratedImage from './ImageGenerator/GeneratedImage';
import { fieldOptions, dimensionPresets } from './ImageGenerator/constants';


const ImageGenerator = () => {
    const [formData, setFormData] = useState({
        prompt: '',
        width: 1200,
        height: 1800,
        model: 'flux',
        seed: '224488',
        enhance: false,
        dimensionPreset: 'custom',
        numImages: 1,
    });

    const [generatedImageUrls, setGeneratedImageUrls] = useState([]);

    const [isLoading, setIsLoading] = useState(false);
    const navigate = useNavigate();
    const [wordCount, setWordCount] = useState(0);
    const [mobileView, setMobileView] = useState('prompt');
    const [isPromptValid, setIsPromptValid] = useState(true);
    // eslint-disable-next-line
    const [isImageSaved, setIsImageSaved] = useState(false);
    const saveAttemptedRef = useRef(false);
    const [adultImageCount, setAdultImageCount] = useState(0);
    const [hasSavedImages, setHasSavedImages] = useState(false);

    // Add new state for generation tracking
    const [generationCount, setGenerationCount] = useState(0);
    const [generationLimit, setGenerationLimit] = useState(13);
    const [generationReset, setGenerationReset] = useState(null);
    const [generationError, setGenerationError] = useState(null);

    const formatTimeUntilReset = (resetTime) => {
        if (!resetTime) return '';

        const reset = new Date(resetTime);
        const now = new Date();
        const diff = reset.getTime() - now.getTime();

        if (diff < 0) return 'less than 1 minute';

        const minutes = Math.floor(diff / (1000 * 60));
        const hours = Math.floor(minutes / 60);
        const remainingMinutes = minutes % 60;

        if (hours > 0) {
            return `${hours} hour${hours === 1 ? '' : 's'} ${remainingMinutes} minute${remainingMinutes === 1 ? '' : 's'}`;
        } else if (minutes > 0) {
            return `${minutes} minute${minutes === 1 ? '' : 's'}`;
        } else {
            return 'less than 1 minute';
        }
    };

    const { MessageDisplay, setMessage, clearMessage } = useMessage();

    const generateRandomSeed = () => {
        const randomSeed = Math.floor(Math.random() * 1000000).toString();
        setFormData(prev => ({
            ...prev,
            seed: randomSeed
        }));
    };
    useDocumentHead(
        'Design Creator - Create Unique Designs with AI | Rangrez AI',
        'Generate stunning, high-quality designs, logos, and more instantly with Rangrez AI. Perfect for artists, creators, and marketers looking for unique visuals tailored to their needs. Try it free on Rangrez AI!'
    );

    useEffect(() => {
        if (isImageGenerationLimited()) {
            navigate('/login', { state: { from: '/generate-image' } });
        }
    }, [navigate]);

    const saveImageToFolder = useCallback(async (imageUrls) => {
        if (!isLoggedIn() || hasSavedImages || saveAttemptedRef.current) return;

        saveAttemptedRef.current = true;

        try {
            const response = await authenticatedApiCall(
                '/save-designs-to-folder',
                'POST',
                {
                    imageUrls: imageUrls
                }
            );

            if (response.success) {
                setHasSavedImages(true);
            } else {
                setMessage(response.error || "Failed to save images", 'error');
            }
        } catch (error) {
            setMessage(error.message || "An unexpected error occurred", 'error');
        } finally {
            setTimeout(() => clearMessage(), 3000);
        }
    }, [hasSavedImages, setMessage, clearMessage]);

    const handleImageLoad = useCallback(() => {

        if (isLoggedIn() && !hasSavedImages && generatedImageUrls.length > 0) {
            saveImageToFolder(generatedImageUrls);
        }
    }, [hasSavedImages, generatedImageUrls, saveImageToFolder]);

    useEffect(() => {
        if (generatedImageUrls.length > 0) {
            setHasSavedImages(false);
            saveAttemptedRef.current = false;
        }
    }, [generatedImageUrls]);

    useEffect(() => {
        generatedImageUrls.forEach((url, index) => {
            if (url) {
                const img = new Image();
                img.onload = handleImageLoad;
                img.src = url;
            }
        });
    }, [generatedImageUrls, handleImageLoad]);

    const handleChange = useCallback((e) => {
        const { name, value, type } = e.target;

        setFormData(prevData => {
            let newValue = value;

            if (name === 'prompt') {
                const words = value.split(/\s+/);
                const truncatedWords = words.map(word => word.slice(0, 35));
                newValue = truncatedWords.join(' ');
                const newWordCount = truncatedWords.filter(word => word.length > 0).length;

                if (newWordCount > 600) {
                    newValue = truncatedWords.slice(0, 600).join(' ');
                    setMessage('Prompt is limited to 600 words, each word up to 35 characters.', 'error');
                }

                setWordCount(Math.min(newWordCount, 600));
            } else if (name === 'dimensionPreset') {
                // Update width and height based on preset
                if (value !== 'custom') {
                    return {
                        ...prevData,
                        dimensionPreset: value,
                        width: dimensionPresets[value].width,
                        height: dimensionPresets[value].height
                    };
                }
            } else if (name === 'width' || name === 'height') {
                // Allow any number input, including empty string
                newValue = value === '' ? '' : Number(value);
            } else if (type === 'number') {
                newValue = Number(value);
            }

            return { ...prevData, [name]: newValue };
        });
        // eslint-disable-next-line
    }, [setMessage]);


    const handleImprovePrompt = async () => {
        if (!formData.prompt) {
            setMessage("Please enter a prompt to improve.", 'error');
            return;
        }
        setMessage("Improving your prompt...", 'info');

        const {
            prompt,
        } = formData;

        const fullPrompt = prompt;

        try {
            const response = await authenticatedApiCall(
                '/improve-prompt',
                'POST',
                { prompt: fullPrompt }
            );
            if (response.improvedPrompt) {
                setFormData(prevData => ({
                    ...prevData,
                    prompt: response.improvedPrompt
                }));
                setWordCount(response.improvedPrompt.split(/\s+/).filter(word => word.length > 0).length);
                setMessage("Prompt improved successfully!", 'success');
            }
        } catch (error) {
            setMessage("Failed to improve prompt. Please try again.", 'error');
        } finally {
            clearMessage();
        }
    };

    const handleRandomPrompt = async () => {
        setMessage("Generating random prompt...", 'info');
        try {
            const response = await authenticatedApiCall('/random-prompt', 'GET');
            if (response.randomPrompt) {
                setFormData(prevData => ({
                    ...prevData,
                    prompt: response.randomPrompt
                }));
                setWordCount(response.randomPrompt.split(/\s+/).filter(word => word.length > 0).length);
                setMessage("Random prompt generated successfully!", 'success');
                setTimeout(() => {
                    clearMessage();
                }, 3000);
            }
        } catch (error) {
            setMessage("Failed to generate random prompt. Please try again.", 'error');
            setTimeout(() => {
                clearMessage();
            }, 3000);
        }
    };

    const isUserRestricted = () => {
        const restrictExpiresAt = localStorage.getItem('restrictExpiresAt2');
        if (!restrictExpiresAt) return false;

        const expirationDate = new Date(restrictExpiresAt);
        return expirationDate > new Date();
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (generationCount >= generationLimit) {
            setGenerationError({
                message: 'Hourly generation limit reached',
                count: generationCount,
                reset: generationReset,
                limit: generationLimit
            });
            return;
        }

        if (isUserRestricted()) {
            const restrictExpiresAt = localStorage.getItem('restrictExpiresAt');
            const expirationDate = new Date(restrictExpiresAt);
            const now = new Date();

            // Calculate remaining time
            const timeDiff = expirationDate - now;
            const days = Math.floor(timeDiff / (1000 * 60 * 60 * 24));
            const hours = Math.floor((timeDiff % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
            const minutes = Math.floor((timeDiff % (1000 * 60 * 60)) / (1000 * 60));

            let timeMessage;
            if (days > 0) {
                timeMessage = `${days} day${days > 1 ? 's' : ''}`;
            } else if (hours > 0) {
                timeMessage = `${hours} hour${hours > 1 ? 's' : ''}`;
            } else {
                timeMessage = `${minutes} minute${minutes > 1 ? 's' : ''}`;
            }

            setMessage(`Your account is temporarily under restriction for ${timeMessage} due to continuous violations of terms of service. Further violations may result in a permanent ban.`, 'error');
            setIsLoading(false);
            return;
        }

        if (!isPromptValid) {
            setMessage("Please revise your prompt to remove explicit content.", 'error');
            setTimeout(() => setMessage(null, null), 3000);
            return;
        }

        if (isImageGenerationLimited()) {
            navigate('/login', { state: { from: '/generate-image' } });
            return;
        }

        if (isLoggedIn() && adultImageCount >= 30) {
            return; // AdultContentWarning component will handle the UI
        }

        // Validate and adjust width and height before submitting
        const validatedFormData = {
            ...formData,
            width: Math.max(500, Math.min(3800, Number(formData.width) || 500)),
            height: Math.max(500, Math.min(3800, Number(formData.height) || 500))
        };

        // Remove seed field if multiple images are selected
        if (validatedFormData.numImages > 1) {
            delete validatedFormData.seed;
        }

        setFormData(validatedFormData); // Update the form data with validated values
        setIsLoading(true);
        setGeneratedImageUrls([]);
        setIsImageSaved(false);
        saveAttemptedRef.current = false;

        const {
            prompt,
        } = validatedFormData;

        // Check if prompt exists
        if (!prompt) {
            setIsLoading(false);
            return;
        }

        const fullPrompt = prompt;

        try {
            setMessage("Generating...", 'info');
            const response = await authenticatedApiCall(
                process.env.REACT_APP_IMAGE,
                'POST',
                { ...validatedFormData, prompt: fullPrompt }
            );

            if (response.imageUrls) {
                setGeneratedImageUrls(response.imageUrls);

                if (response.generation_count !== undefined) {
                    setGenerationCount(response.generation_count);
                    setGenerationLimit(response.generation_limit);
                    setGenerationReset(response.generation_reset);
                }

                if (!isLoggedIn()) {
                    incrementImageGenerationCount();
                } else if (response.adult_images_count !== undefined) {
                    setAdultImageCount(response.adult_images_count);
                }
            } else {
                throw new Error('No images in response');
            }
        } catch (error) {
            if (error.response && error.response.status === 403 && error.response.data.error) {
                if (error.response.data.restrict_expires_at) {
                    localStorage.setItem('restrictExpiresAt', error.response.data.restrict_expires_at);
                }
                setMessage(error.response.data.error, 'error');
                setIsLoading(false);
                return;
            }
            if (error.response?.status === 429) {
                const data = error.response.data;
                setGenerationError({
                    message: 'Hourly generation limit reached',
                    count: data.current_count,
                    reset: data.reset_time,
                    limit: data.limit
                });
            }
            setMessage("Failed to generate image. Please try again.", 'error');
        } finally {
            clearMessage();
            setIsLoading(false);
        }
    };

    if (adultImageCount >= 20) {
        return (
            <>
                {isLoggedIn() && <AdultContentWarning isVisible={adultImageCount >= 20} />}
            </>
        )
    }

    return (
        <div className="imgen-image-generator margin">
            {/* <p className='imgen-credit'>powered by
                <span><a href="https://groq.com/groqcloud/" target='_blank' rel='noreferrer noopener'>GroqCloud</a></span>
                &
                <span><a href="https://pollinations.ai" target='_blank' rel='noreferrer noopener'>Pollinations AI</a></span>
            </p> */}
            <MessageDisplay />

            <div className="imgen-status-messages">
                {generationError && (
                    <div className="imgen-alert imgen-alert-error">
                        <div className="imgen-alert-content">
                            <p>{generationError.message}</p>
                            {generationError.reset && (
                                <p className="imgen-alert-details">
                                    {`${generationError.count}/${generationError.limit} generations used. Resets in ${formatTimeUntilReset(generationError.reset)}.`}
                                </p>
                            )}
                        </div>
                    </div>
                )}

                {generationCount > 0 && !generationError && (
                    <div className="imgen-alert imgen-alert-info">
                        <div className="imgen-alert-content">
                            <p>
                                {`${generationCount}/${generationLimit} generations. Resets in ${formatTimeUntilReset(generationReset)}.`}
                                {generationLimit === 100 && (
                                    <> <Link to="/follow" target='_blank'>Increase</Link></>
                                )}
                            </p>
                        </div>
                    </div>
                )}
            </div>

            {/* Mobile View */}
            <div className="imgen-mobile-view">
                <div className="imgen-mobile-tabs">
                    <button
                        onClick={() => setMobileView('prompt')}
                        className={`imgen-tab-button ${mobileView === 'prompt' ? 'active' : ''}`}
                    >
                        Prompt
                    </button>
                    <button
                        onClick={() => setMobileView('settings')}
                        className={`imgen-tab-button ${mobileView === 'settings' ? 'active' : ''}`}
                    >
                        Settings
                    </button>
                </div>
                <div className="imgen-mobile-content">
                    <form onSubmit={handleSubmit}>
                        {mobileView === 'prompt' ? (
                            <PromptSection
                                formData={formData}
                                wordCount={wordCount}
                                isLoading={isLoading}
                                handleChange={handleChange}
                                generateRandomSeed={generateRandomSeed}
                                handleImprovePrompt={handleImprovePrompt}
                                handleRandomPrompt={handleRandomPrompt}
                                setIsPromptValid={setIsPromptValid}
                            />
                        ) : (
                            <SettingsPanel
                                formData={formData}
                                handleChange={handleChange}
                                fieldOptions={fieldOptions}
                                dimensionPresets={dimensionPresets}
                            />
                        )}
                    </form>
                </div>
            </div>

            {/* Desktop View */}
            <div className="imgen-desktop-view">
                {/* Left Sidebar */}
                <div className="imgen-sidebar left-sidebar">
                    <form onSubmit={handleSubmit}>
                        <PromptSection
                            formData={formData}
                            wordCount={wordCount}
                            isLoading={isLoading}
                            handleChange={handleChange}
                            generateRandomSeed={generateRandomSeed}
                            handleImprovePrompt={handleImprovePrompt}
                            handleRandomPrompt={handleRandomPrompt}
                            setIsPromptValid={setIsPromptValid}
                        />
                    </form>
                </div>

                {/* Right Sidebar */}
                <div className="imgen-sidebar right-sidebar">
                    <SettingsPanel
                        formData={formData}
                        handleChange={handleChange}
                        fieldOptions={fieldOptions}
                        dimensionPresets={dimensionPresets}
                    />
                </div>

                {/* Center Content */}
                <div className="imgen-main-content">
                    <GeneratedImage
                        isLoading={isLoading}
                        generatedImageUrls={generatedImageUrls}
                        isMobileView={false}
                    />
                </div>
            </div>

            {/* Mobile Generated Image and Buttons */}
            <div className="imgen-mobile-generated-image">
                <GeneratedImage
                    isLoading={isLoading}
                    generatedImageUrls={generatedImageUrls}
                    isMobileView={true}
                />
            </div>
        </div>
    );
};

export default ImageGenerator;