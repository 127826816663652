import React, { useCallback, useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import '../css/ImageCard.css';
import { ImageSettings } from './ImageCard/ImageSettings';
import { MoreOptions } from './ImageCard/MoreOptions';
import { ImageRenderer } from './ImageCard/ImageRenderer';
import DesignViewer from './Tools/Design/DesignViewer';

const MoreOptionsContext = React.createContext();

export const MoreOptionsProvider = ({ children }) => {
    const [activeMoreOptions, setActiveMoreOptions] = useState(null);
    return (
        <MoreOptionsContext.Provider value={{ activeMoreOptions, setActiveMoreOptions }}>
            {children}
        </MoreOptionsContext.Provider>
    );
};

const ImageCard = React.memo(({
    image,
    isAdmin,
    fromDashboard,
    onRemoveReportedStatus,
    onDeleteImage,
    index,
    selectedImageIndex,
    handleLike,
    handleDownload,
    handleDelete,
    handleReport,
    buttonStates,
    likeInProgress,
    isOwnProfile,
    handleCopyMarkdownLink,
    isLikedImage,
    hideUserInfo,
    isMainImage,
    isToolGenerated,
    isShowingInContent,
    isOwnContentImages,
    isAdminView,
    handleShareClick,
    handleShowSettings,
    handleSetAsProfile,
    onToggleAdultStatus,
    isLoggedIn,
    isRelatedImage,
    isCustomizedDesign
}) => {
    const navigate = useNavigate();
    const [imageLoaded, setImageLoaded] = useState(false);
    // eslint-disable-next-line
    const [profileImageLoaded, setProfileImageLoaded] = useState(false);
    // eslint-disable-next-line
    const [isMobile, setIsMobile] = useState(false);
    const [is_liked, setIsLiked] = useState(image.is_liked);
    const [isLandscape, setIsLandscape] = useState(false);
    const [isHidden, setIsHidden] = useState(false);
    const [isBlurred, setIsBlurred] = useState(image.isAdult);
    const [showHeartAnimation, setShowHeartAnimation] = useState(false);
    const [showDesignViewer, setShowDesignViewer] = useState(false);
    const imageRef = useRef(null);
    const clickTimeoutRef = useRef(null);
    const clickCountRef = useRef(0);
    const [showMoreOptions, setShowMoreOptions] = useState(false);
    const moreOptionsRef = useRef(null);
    const [showSettings, setShowSettings] = useState(false);
    const [settingsData, setSettingsData] = useState(null);

    const { activeMoreOptions, setActiveMoreOptions } = React.useContext(MoreOptionsContext);

    // eslint-disable-next-line
    const getPlaceholderColor = (id) => {
        const colorIndex = typeof id === 'string'
            ? Math.abs(id.split('').reduce((acc, char) => acc + char.charCodeAt(0), 0)) % 8 + 1
            : (id % 8) + 1;
        return `color-${colorIndex}`;
    };

    const previousUrl = window.location.href;

    // Detect mobile only once on mount
    useEffect(() => {
        const checkMobile = () => {
            // const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
            // const isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent);
            // setIsMobile(window.innerWidth <= 768 || (isSafari && isIOS));
            setIsMobile(window.innerWidth <= 768);
        };

        checkMobile();
        const handleResize = () => {
            requestAnimationFrame(checkMobile);
        };

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    // Cleanup function to remove event listeners and reset states
    useEffect(() => {
        return () => {
            if (clickTimeoutRef.current) {
                clearTimeout(clickTimeoutRef.current);
            }
            setShowMoreOptions(false);
            setActiveMoreOptions(null);
        };
    }, [setActiveMoreOptions]);

    // Reset more options when active options change
    useEffect(() => {
        if (activeMoreOptions && activeMoreOptions !== image.id) {
            setShowMoreOptions(false);
        }
    }, [activeMoreOptions, image.id]);

    const handleSettingsClick = useCallback(async (e) => {
        e.stopPropagation();
        setShowSettings(true);
        const response = await handleShowSettings(image.imageUrl, image.id);
        if (!response?.success) {
            setShowSettings(false);
            return;
        }
        setSettingsData(response.settings);
    }, [handleShowSettings, image.imageUrl, image.id]);

    const handleMoreClick = useCallback((e) => {
        e.stopPropagation();
        e.preventDefault();

        const newState = !showMoreOptions;
        setShowMoreOptions(newState);
        setActiveMoreOptions(newState ? image.id : null);

    }, [showMoreOptions, image.id, setActiveMoreOptions]);

    const handleClose = () => {
        setShowDesignViewer(false);
        // Restore the previous URL when closing
        window.history.pushState({}, '', previousUrl);
    };
    const handleEditClick = useCallback((e) => {
        e.stopPropagation();
        setShowDesignViewer(true);
    }, []);

    const handleCopyMarkdownLinkClick = useCallback((e) => {
        e.stopPropagation();
        handleCopyMarkdownLink(image.imageUrl, image.id, image.title);
    }, [handleCopyMarkdownLink, image.imageUrl, image.id, image.title]);

    const handleCardClick = useCallback((e) => {
        const isLoggedIn = !!localStorage.getItem('token');

        e.preventDefault();
        clickCountRef.current += 1;

        if (clickTimeoutRef.current) {
            clearTimeout(clickTimeoutRef.current);
        }
        clickTimeoutRef.current = setTimeout(() => {
            if (clickCountRef.current === 1) {
                if (!isLoggedIn && isRelatedImage) {
                    navigate('/login');
                } else if (!isRelatedImage && !showDesignViewer) {
                    navigate(`/design/${image.id}`, { state: { image } });
                }
            } else if (clickCountRef.current === 2) {
                if (!isLoggedIn) {
                    navigate('/login');
                    return;
                }

                if (!is_liked && !likeInProgress[image.id]) {
                    handleLike(image.id, index);
                    setIsLiked(true);
                    setShowHeartAnimation(true);
                    setTimeout(() => setShowHeartAnimation(false), 1000);
                }
            }
            clickCountRef.current = 0;
        }, 300);
    }, [navigate, image, handleLike, index, is_liked, likeInProgress, isRelatedImage, showDesignViewer]);

    const handleLikeClick = useCallback((e) => {
        e.stopPropagation();
        if (!isLoggedIn && isRelatedImage) {
            navigate('/login');
            return;
        }
        handleLike(image.id, index);
        setIsLiked(!is_liked);
        if (!is_liked) {
            setShowHeartAnimation(true);
            setTimeout(() => setShowHeartAnimation(false), 1000);
        }
    }, [handleLike, image.id, index, is_liked, isLoggedIn, navigate, isRelatedImage]);

    const handleDownloadClick = useCallback((e) => {
        e.stopPropagation();
        handleDownload(image.imageUrl, image.id);
    }, [handleDownload, image.imageUrl, image.id]);

    const handleDeleteClick = useCallback((e) => {
        e.stopPropagation();
        handleDelete(image, image.id, index);
    }, [handleDelete, image, index]);

    const handleShare = useCallback((e) => {
        e.stopPropagation();
        handleShareClick(image.id);
    }, [handleShareClick, image.id]);

    const handleImageLoad = useCallback(() => {
        setImageLoaded(true);
        if (imageRef.current) {
            setIsLandscape(imageRef.current.width > imageRef.current.height);
        }
    }, []);

    const handleReportClick = useCallback((e) => {
        e.stopPropagation();
        handleReport(image.id);
    }, [handleReport, image.id]);

    const handleProfileImageLoad = useCallback(() => {
        setProfileImageLoaded(true);
    }, []);

    const handleToggleHide = useCallback((e) => {
        e.stopPropagation();
        setIsHidden(!isHidden);
    }, [isHidden]);

    const handleToggleBlur = useCallback((e) => {
        e.stopPropagation();
        setIsBlurred(!isBlurred);
    }, [isBlurred]);

    const handleSetAsProfileClick = useCallback((e) => {
        e.stopPropagation();
        handleSetAsProfile(image.imageUrl, image.id)
    }, [image.imageUrl, image.id, handleSetAsProfile])

    return (
        <>
            <ImageRenderer
                image={image}
                index={index}
                fromDashboard={fromDashboard}
                selectedImageIndex={selectedImageIndex}
                imageRef={imageRef}
                imageLoaded={imageLoaded}
                isHidden={isHidden}
                isBlurred={isBlurred}
                showMoreOptions={showMoreOptions}
                showHeartAnimation={showHeartAnimation}
                isMainImage={isMainImage}
                isToolGenerated={isToolGenerated}
                isRelatedImage={isRelatedImage}
                isLandscape={isLandscape}
                is_liked={image.is_liked}
                isOwnProfile={isOwnProfile}
                isAdminView={isAdminView}
                isLoggedIn={isLoggedIn}
                likeInProgress={likeInProgress}
                handleCardClick={handleCardClick}
                handleLikeClick={handleLikeClick}
                handleMoreClick={handleMoreClick}
                handleToggleBlur={handleToggleBlur}
                onDeleteImage={onDeleteImage}
                handleDeleteClick={handleDeleteClick}
                handleImageLoad={handleImageLoad}
                setActiveMoreOptions={setActiveMoreOptions}
                setShowMoreOptions={setShowMoreOptions}
                isCustomizedDesign={isCustomizedDesign}
                hideUserInfo={hideUserInfo}
                handleProfileImageLoad={handleProfileImageLoad}
                handleEditClick={handleEditClick}
            />
            {!isRelatedImage && (
                <>
                    {showDesignViewer && (
                        <DesignViewer
                            design={{
                                id: image.id,
                                image_url: image.imageUrl,
                                title: image.title,
                                submitted_by: image.username,
                            }}
                            onClose={handleClose}
                            type={image.design_type}
                            customProps={{
                                inputPlaceholder: `Describe how you want to modify this ${image.design_type}...`
                            }}
                        />
                    )}
                    <ImageSettings
                        showSettings={showSettings}
                        settingsData={settingsData}
                        setShowSettings={setShowSettings}
                    />
                    <MoreOptions
                        showMoreOptions={showMoreOptions}
                        image={image}
                        buttonStates={buttonStates}
                        handleShare={handleShare}
                        handleSettingsClick={handleSettingsClick}
                        onToggleAdultStatus={onToggleAdultStatus}
                        handleDownloadClick={handleDownloadClick}
                        isHidden={isHidden}
                        handleToggleHide={handleToggleHide}
                        isBlurred={isBlurred}
                        handleToggleBlur={handleToggleBlur}
                        handleSetAsProfileClick={handleSetAsProfileClick}
                        handleDeleteClick={handleDeleteClick}
                        handleReportClick={handleReportClick}
                        isAdmin={isAdmin}
                        isLikedImage={isLikedImage}
                        isOwnContentImages={isOwnContentImages}
                        isOwnProfile={isOwnProfile}
                        isShowingInContent={isShowingInContent}
                        isToolGenerated={isToolGenerated}
                        onDeleteImage={onDeleteImage}
                        onRemoveReportedStatus={onRemoveReportedStatus}
                        moreOptionsRef={moreOptionsRef}
                        setShowMoreOptions={setShowMoreOptions}
                        setActiveMoreOptions={setActiveMoreOptions}
                        handleCopyMarkdownLink={handleCopyMarkdownLinkClick}
                    />
                </>
            )}
        </>
    );
});

export default ImageCard;