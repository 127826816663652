import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { authenticatedApiCall } from '../../../utils/authenticatedApiCall';
import DesignViewer from './DesignViewer';
import { Loader } from 'lucide-react';

const DesignViewerPage = () => {
    const { imageId } = useParams();
    const navigate = useNavigate();
    const [design, setDesign] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchDesignData = async () => {
            try {
                const designData = await authenticatedApiCall(`/get-image/${imageId}`, 'GET');
                setDesign({
                    id: designData.id,
                    image_url: designData.imageUrl,
                    title: designData.title,
                    submitted_by: designData.username,
                    design_type: designData.design_type || 'design'
                });
            } catch (err) {
                console.error('Error fetching design:', err);
                setError(err);
                navigate('/');
            } finally {
                setLoading(false);
            }
        };

        fetchDesignData();
    }, [imageId, navigate]);

    const handleClose = () => {
        navigate('/');
    };

    if (loading) {
        return (
            <div className="design-viewer-page-loader">
                <Loader size={20} className="animate-spin" />
            </div>
        );
    }

    if (error) {
        return null;
    }

    return (
        <DesignViewer
            design={design}
            onClose={handleClose}
            type={design.design_type || 'art'}
            customProps={{
                inputPlaceholder: `Describe how you want to modify this ${design.design_type || 'art'}...`
            }}
        />
    );
};

export default DesignViewerPage;