import React, { useState } from 'react';
import { MessageCircle, X } from 'lucide-react';
import CommunityChat from './CommunityChat';
import '../css/ChatIcon.css';

const ChatIcon = () => {
    const [isOpen, setIsOpen] = useState(false);
    const [hasBeenOpened, setHasBeenOpened] = useState(false);
  
    const toggleChat = () => {
      if (!isOpen && !hasBeenOpened) {
        setHasBeenOpened(true);
      }
      setIsOpen(!isOpen);
      document.body.style.overflow = !isOpen ? 'hidden' : 'auto';
    };
  
    return (
      <>
        <button 
          onClick={toggleChat}
          className={`ci-button ${isOpen ? 'ci-button--active' : ''}`}
        >
          {isOpen ? (
            <X className="ci-icon" />
          ) : (
            <MessageCircle className="ci-icon" />
          )}
        </button>
  
        <div 
          className={`ci-overlay ${isOpen ? 'ci-overlay--visible' : ''}`}
          onClick={toggleChat}
        />
  
        <div className={`ci-popup ${isOpen ? 'ci-popup--visible' : ''}`}>
          <div className="ci-popup__container">
            {(isOpen || hasBeenOpened) && <CommunityChat shouldBroadcastJoin={true} />}
          </div>
        </div>
      </>
    );
};

export default ChatIcon;