import React, { useEffect, useState, useRef } from 'react';
import { useNavigate, useLocation, Link } from 'react-router-dom';
import axios from 'axios';
import { AiOutlineCheckCircle } from 'react-icons/ai';
import { FaGithub } from 'react-icons/fa';
import '../../css/GoogleSignIn.css';
import { isImageGenerationLimited } from '../../utils/cookies';
import { useDocumentHead } from '../../hooks/useDocumentHead';

const API_URL = process.env.REACT_APP_API;
// const API_URL = "http://localhost:5000";

const SignIn = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [showImageGenerationReason, setShowImageGenerationReason] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [emailSubscription, setEmailSubscription] = useState(true);
  const emailSubscriptionRef = useRef(emailSubscription);
  const [quickJoinUsername, setQuickJoinUsername] = useState('');
  const [quickJoinPassword, setQuickJoinPassword] = useState('');
  const [formErrors, setFormErrors] = useState({
    username: '',
    password: ''
  });

  useDocumentHead(
    'Account - Sign In | Rangrez AI',
    'Create an account or log in to Rangrez AI. Start generating unique designs, logos, and content with easy signup and secure login options.'
  );

  useEffect(() => {
    setShowImageGenerationReason(isImageGenerationLimited() && location.state?.from === '/generate-image');
  }, [location]);

  useEffect(() => {
    emailSubscriptionRef.current = emailSubscription;
  }, [emailSubscription]);

  useEffect(() => {
    const loadGoogleScript = () => {
      const script = document.createElement('script');
      script.src = 'https://accounts.google.com/gsi/client';
      script.async = true;
      script.defer = true;
      document.body.appendChild(script);
      script.onload = initializeGoogleLogin;
    };
    loadGoogleScript();
    // eslint-disable-next-line
  }, []);

  const validateForm = () => {
    let errors = { username: '', password: '' };
    let isValid = true;

    // Username validation
    if (!quickJoinUsername.trim()) {
      errors.username = 'Username is required';
      isValid = false;
    } else if (quickJoinUsername.length < 4) {
      errors.username = 'Username must be at least 4 characters';
      isValid = false;
    } else if (quickJoinUsername.length > 20) {
      errors.username = 'Username must be max 20 characters';
      isValid = false;
    } else if (!/^[a-zA-Z0-9_]+$/.test(quickJoinUsername)) {
      errors.username = 'Username can only contain letters, numbers, and underscores';
      isValid = false;
    }

    // Password validation
    if (!quickJoinPassword.trim()) {
      errors.password = 'Password is required';
      isValid = false;
    } else if (quickJoinPassword.length < 6) {
      errors.password = 'Password must be at least 6 characters';
      isValid = false;
    }

    setFormErrors(errors);
    return isValid;
  };

  const handleQuickJoin = async (e) => {
    e.preventDefault();

    // Validate form before submission
    if (!validateForm()) {
      return;
    }

    try {
      const res = await axios.post(`${API_URL}/quick-join`, {
        username: quickJoinUsername,
        password: quickJoinPassword,
      }, {
        headers: {
          'Content-Type': 'application/json',
          'X-Key': process.env.REACT_APP_KEY,
        }
      });
      const { token, user } = res.data;
      localStorage.setItem('token', token);
      localStorage.setItem('user', JSON.stringify(user));
      navigate(location.state?.from || `/profile/${user.username}`);
    } catch (error) {
      handleLoginError(error);
    }
  };

  const initializeGoogleLogin = () => {
    window.google.accounts.id.initialize({
      client_id: process.env.REACT_APP_GOOGLE_CLIENT_ID,
      callback: (response) => handleGoogleLogin(response, emailSubscriptionRef.current),
    });
    window.google.accounts.id.renderButton(
      document.getElementById('google-login-button'),
      { theme: 'filled_blue', size: 'large', shape: 'pill', text: 'continue_with' }
    );
  };

  const handleGoogleLogin = async (response, emailSubscription) => {
    try {
      const res = await axios.post(`${API_URL}/google-login`, {
        token: response.credential,
        emailSubscription: emailSubscription,
      }, {
        headers: {
          'Content-Type': 'application/json',
          'X-Key': process.env.REACT_APP_KEY,
        }
      });
      const { token, user } = res.data;
      localStorage.setItem('token', token);
      localStorage.setItem('user', JSON.stringify(user));
      navigate(location.state?.from || `/profile/${user.username}`);
    } catch (error) {
      handleLoginError(error);
    }
  };

  const handleGithubLogin = (redirectOrigin) => {
    const githubClientId = process.env.REACT_APP_GITHUB_CLIENT_ID;
    const redirectUri = `https://rangrezai.com/github-callback`;
    const scope = 'user:email';

    const state = JSON.stringify({
      redirectOrigin: redirectOrigin || window.location.origin,
      emailSubscription: emailSubscription
    });

    window.location.href = `https://github.com/login/oauth/authorize?client_id=${githubClientId}&redirect_uri=${redirectUri}&scope=${scope}&state=${encodeURIComponent(state)}`;
  };

  const handleLoginError = (error) => {
    console.error('Error during login:', error);
    if (error.response) {
      console.error('Error response:', error.response.data);
      if (error.response.status === 403) {
        setErrorMessage(error.response.data.error);
      } else {
        setErrorMessage('Username and password did not matched or some unexpected error occurred.');
      }
    }
  };

  // Check if the current domain is rangrezai.com
  const isRangrezDotCom = window.location.hostname === 'rangrezai.com';

  return (
    <div className="gs-container">
      <div className="gs-content">
        <div className="gs-left-side">
          <div className="gs-logo-container">
            <img src="/images/Rangrez-AI.jpg" alt="Logo" className="gs-logo" />
          </div>
          <h1 className="gs-title">Welcome to Rangrez AI</h1>
          {!showImageGenerationReason && !errorMessage && (
            <p className="gs-subtitle">Sign in to access all features</p>
          )}
          {showImageGenerationReason && !errorMessage && (
            <p className="gs-subtitle">
              😊 Enjoying the image creation? Simply log in to keep generating for free!
            </p>
          )}
          <div className="gs-benefits">
            <h2>Unlock Your Full Creative Potential</h2>
            <ul>
              <li><AiOutlineCheckCircle /> Personalized experience.</li>
              <li><AiOutlineCheckCircle /> 2400 daily generations.</li>
              <li><AiOutlineCheckCircle /> Auto-saved creations.</li>
              <li><AiOutlineCheckCircle /> Follow top creators.</li>
              <li><AiOutlineCheckCircle /> Join a creative community.</li>
            </ul>
          </div>
        </div>
        <div className="gs-divider"></div>
        <div className="gs-right-side">
          {errorMessage && (
            <div className="gs-error-container">
              <p className="gs-error-message">{errorMessage}</p>
            </div>
          )}
          <div className="gs-signin-buttons">
            <form className="quick-join-form" onSubmit={handleQuickJoin}>
              <div className="quick-join-input-container">
                <input
                  type="text"
                  placeholder="Choose a username"
                  className={`quick-join-input ${formErrors.username ? 'input-error' : ''}`}
                  value={quickJoinUsername}
                  onChange={(e) => {
                    setQuickJoinUsername(e.target.value);
                    setFormErrors(prev => ({ ...prev, username: '' }));
                  }}
                />
                {formErrors.username && (
                  <p className="quick-join-error-message">{formErrors.username}</p>
                )}
              </div>
              <div className="quick-join-input-container">
                <input
                  type="password"
                  placeholder="Set a password"
                  className={`quick-join-input ${formErrors.password ? 'input-error' : ''}`}
                  value={quickJoinPassword}
                  onChange={(e) => {
                    setQuickJoinPassword(e.target.value);
                    setFormErrors(prev => ({ ...prev, password: '' }));
                  }}
                />
                {formErrors.password && (
                  <p className="quick-join-error-message">{formErrors.password}</p>
                )}
              </div>
              <button type="submit" className="quick-join-button">
                Quick Join
              </button>
            </form>
            <p className="quick-join-note">
              Forgotten passwords cannot be recovered when quick joined!
            </p>
            <div className="google-button-placeholder">Sign in with Google</div>
            <div id="google-login-button"></div>
            {isRangrezDotCom && (
              <button
                onClick={() => handleGithubLogin(window.location.origin)}
                className="github-login-button"
              >
                <FaGithub className="github-icon" />
                Continue with GitHub
              </button>
            )}
          </div>
          <p className="gs-terms">
            <div className="email-subscription-container">
              <label className="email-subscription-label">
                <input
                  type="checkbox"
                  checked={emailSubscription}
                  onChange={() => setEmailSubscription(!emailSubscription)}
                />
                <span>Be the first to know about updates and special offers</span>
              </label>
            </div>
            By signing in, you agree to our <Link to="/terms">Terms of Service</Link> and <Link to="/privacy">Privacy Policy</Link>
          </p>
        </div>
      </div>
    </div>
  );
};

export default SignIn;