import React, { useState } from 'react';
import { Heart, MoreHorizontal, Image, Trash2, Clock, Star, User, Pencil } from 'lucide-react';
import { Link } from 'react-router-dom';
import { formatTimestamp } from '../../utils/dateFormatter';

export const ImageRenderer = ({
    image,
    index,
    selectedImageIndex,
    isHidden,
    isBlurred,
    showMoreOptions,
    showHeartAnimation,
    isMainImage,
    isToolGenerated,
    isRelatedImage,
    isLandscape,
    is_liked,
    isOwnProfile,
    isAdminView,
    isLoggedIn,
    likeInProgress,
    handleCardClick,
    handleLikeClick,
    handleMoreClick,
    handleToggleBlur,
    onDeleteImage,
    handleDeleteClick,
    setActiveMoreOptions,
    setShowMoreOptions,
    isCustomizedDesign,
    hideUserInfo,
    handleProfileImageLoad,
    handleEditClick

}) => {
    const [imageLoaded, setImageLoaded] = useState(false);

    const getPlaceholderColor = (id) => {
        const colorIndex = typeof id === 'string'
            ? Math.abs(id.split('').reduce((acc, char) => acc + char.charCodeAt(0), 0)) % 8 + 1
            : (id % 8) + 1;
        return `color-${colorIndex}`;
    };

    const handleBackdropClick = (e) => {
        if (e.target.className === 'ic-more-options-backdrop') {
            e.stopPropagation();
            setShowMoreOptions(false);
            setActiveMoreOptions(null);
        }
    };

    const handleImageLoad = () => {
        setImageLoaded(true);
    };

    const handleEditButtonClick = (e) => {
        e.preventDefault(); 
        e.stopPropagation(); 
        handleEditClick(e);
    };

    return (
        <div className={`ic-image-card ${selectedImageIndex === index ? 'ic-selected' : ''} 
            ${isMainImage ? 'ic-main-image' : ''} ${isToolGenerated ? 'ic-tool-image' : ''}
            ${!image.is_approved ? 'ic-not-approved' : ''}
            ${isCustomizedDesign ? 'ic-customized-design' : ''}`}
            onClick={!isToolGenerated ? handleCardClick : undefined}
        >
            <div className={`ic-image-wrapper ${showMoreOptions ? 'ic-show' : ''}`}>
                {!isHidden && (
                    <img
                        src={image.imageUrl}
                        alt={image.title || `Generated by ${image.username}`}
                        className={`ic-image ${imageLoaded ? 'ic-loaded' : ''} ${isBlurred ? 'ic-blurred' : ''} ${isMainImage ? 'ic-main-image-img' : ''}`}
                        onLoad={handleImageLoad}
                    />
                )}
                {!imageLoaded && !isHidden && (
                    <div className={`ic-image-placeholder ${getPlaceholderColor(image.id || index)}`} />
                )}
                {isHidden && (
                    <div className="ic-hidden-image-placeholder">Image Hidden</div>
                )}

                <div className={`ic-heart-animation ${showHeartAnimation ? 'active' : ''}`}>
                    <Heart size={70} fill="yellow" />
                </div>

                {isRelatedImage && image.isAdult && (
                    <div className="ic-adult-content-overlay">
                        <div className="ic-image-reason">{image.reason}</div>
                    </div>
                )}

                {isRelatedImage && (
                    <div className="ic-type-badge">
                        {image.design_type}
                        {isCustomizedDesign && (
                            <p><Pencil size={10} /></p>
                        )}
                    </div>
                )}
                {image.isOriginalDesign && (
                    <div className="ldv-original-badge">
                        Base Design
                    </div>
                )}

                {!isRelatedImage && imageLoaded && (
                    <div className={`ic-image-overlay ${selectedImageIndex === index ? 'ic-hidden' : ''}`}>
                        <button
                            onClick={handleEditButtonClick}
                            className="ic-edit-button"
                            aria-label="Edit design"
                        >
                            <Pencil size={16} />
                        </button>
                        <div className="ic-type-badge">
                            {image.design_type}
                            {isCustomizedDesign && (
                                <p><Pencil size={10} /></p>
                            )}
                        </div>
                        {isOwnProfile && !image.is_rejected && (
                            <div className="ic-status-badge" data-status={image.is_approved ? 'approved' : 'pending'}>
                                {image.is_approved ?
                                    <div className='ic-badge-container'>
                                        <Star size={15} />
                                        <p className='ic-badge-tooltip'>Featured</p>
                                    </div>
                                    :
                                    <div className='ic-badge-container'>
                                        <Clock size={15} />
                                        <p className='ic-badge-tooltip'>Under Review</p>
                                    </div>
                                }
                            </div>
                        )}
                        <div className="ic-image-details">
                            {isBlurred && image.isAdult && (
                                <div className="ic-adult-content-overlay">
                                    <div className="ic-image-reason">{image.reason}</div>
                                    {(isOwnProfile || isAdminView) && isLoggedIn && (
                                        <div className="ic-quick-actions">
                                            <button
                                                className="ic-action-button ic-blur"
                                                onClick={handleToggleBlur}
                                                title={isBlurred ? "Unblur Image" : "Blur Image"}
                                            >
                                                <Image size={20} className="ic-action-icon ic-info" />
                                                <span>Unblur</span>
                                            </button>
                                            <button
                                                onClick={isAdminView ? onDeleteImage : handleDeleteClick}
                                                className="ic-action-button ic-delete"
                                                title="Delete"
                                            >
                                                <Trash2 size={20} className="ic-action-icon ic-delete" />
                                                <span>Delete</span>
                                            </button>
                                        </div>
                                    )}
                                </div>
                            )}

                            {showMoreOptions && (
                                <div
                                    className="ic-more-options-backdrop"
                                    onClick={handleBackdropClick}
                                />
                            )}

                            <div className={`ic-image-buttons ${showMoreOptions ? 'ic-show' : ''} ${isLandscape ? 'ic-landscape' : ''}`}>
                                {!isToolGenerated && (
                                    <>
                                        <button
                                            className={`ic-action-button ic-image-likes ${is_liked ? 'ic-liked' : ''} ${isLandscape ? 'ic-landscape' : ''}`}
                                            onClick={handleLikeClick}
                                            disabled={likeInProgress[image.id]}
                                        >
                                            <Heart
                                                size={18}
                                                fill={is_liked ? 'yellow' : 'none'}
                                                color={is_liked ? 'yellow' : '#fff'}
                                            />
                                            {image.likeCount}
                                        </button>
                                        {hideUserInfo && (
                                            <button
                                                className="ic-action-button ic-more-button"
                                                onClick={handleMoreClick}
                                                title="More Options"
                                            >
                                                <MoreHorizontal size={18} className="ic-action-icon" />
                                            </button>
                                        )}
                                    </>
                                )}
                            </div>
                        </div>
                    </div>
                )}
            </div>
            {!hideUserInfo && !isOwnProfile && (
                <div className='ic-user-info-wrapper'>
                    <div className="ic-user-info">
                        {image.userProfileImage ? (
                            <Link to={`/profile/${image.username}`} className="ic-user-link" onClick={(e) => e.stopPropagation()}>
                                <div className={`ic-user-avatar-container ${image.badges?.social_ambassador ? 'ic-social-ambassador' : ''}`}>
                                    <img
                                        src={image.userProfileImage}
                                        alt={image.username}
                                        className="ic-user-avatar ic-loaded"
                                        onLoad={handleProfileImageLoad}
                                    />
                                    {image.badges?.social_ambassador && (
                                        <span className="ic-badge-icon" title="social ambassador badge">
                                            <svg xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24">
                                                <path d="m15,12c0,1.302-.839,2.402-2,2.816v-3.816h-2v3.816c-1.161-.414-2-1.514-2-2.816,0-1.708,1.819-3.67,3-4.708,1.181,1.038,3,3,3,4.708Zm7.08,4.175c.122,1.592-.451,3.167-1.595,4.31-1.144,1.143-2.711,1.718-4.31,1.595-1.039,1.212-2.558,1.92-4.175,1.92s-3.136-.708-4.175-1.92c-1.595.12-3.166-.452-4.31-1.595-1.144-1.144-1.717-2.718-1.595-4.31-1.212-1.039-1.92-2.558-1.92-4.175s.708-3.136,1.92-4.175c-.122-1.592.451-3.167,1.595-4.31,1.144-1.143,2.717-1.717,4.31-1.595,1.039-1.212,2.558-1.92,4.175-1.92s3.136.708,4.175,1.92c1.595-.121,3.167.452,4.31,1.595,1.144,1.144,1.717,2.718,1.595,4.31,1.212,1.039,1.92,2.558,1.92,4.175s-.708,3.136-1.92,4.175Zm-5.08-4.175c0-3.402-3.95-6.462-4.4-6.8l-.6-.45-.6.45c-.45.337-4.4,3.398-4.4,6.8,0,2.414,1.721,4.434,4,4.899v2.101h2v-2.101c2.279-.465,4-2.484,4-4.899Z" />
                                            </svg>
                                        </span>
                                    )}
                                </div>
                            </Link>
                        ) : (
                            <div className="ic-avatar-placeholder">
                                <User size={22} />
                            </div>
                        )}
                        <div className="ic-username-date">
                            <Link to={`/profile/${image.username}`} className="ic-user-link ic-username" onClick={(e) => e.stopPropagation()}>
                                {image.username}
                            </Link>
                            {!isToolGenerated && (
                                <div className="ic-image-date">{formatTimestamp(image.timestamp)}</div>
                            )}
                        </div>
                    </div>
                    {!isRelatedImage && (
                        <button
                            className="ic-action-button ic-more-button"
                            onClick={handleMoreClick}
                            title="More Options"
                        >
                            <MoreHorizontal size={18} className="ic-action-icon" />
                        </button>
                    )}
                </div>
            )}
        </div>
    );
};

export default ImageRenderer;