import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { User, BrainCircuit, Brush, Filter, MenuIcon, X } from 'lucide-react';
import '../css/Header.css';
import { FaInfo, FaQuestion } from 'react-icons/fa';
import FilterComponent from './Filter/FilterComponent';
import { authenticatedApiCall } from '../utils/authenticatedApiCall';

const Header = ({ onFilterChange, activeFilters }) => {
  const isLoggedIn = !!localStorage.getItem('token');
  const location = useLocation();
  const [hasNewNotification, setHasNewNotification] = useState(false);
  const [showFilters, setShowFilters] = useState(false);
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  const isDesignRoute = location.pathname === '/design';

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (event.target.classList.contains('header-filter-container')) {
        setShowFilters(false);
      }
    };

    if (showFilters) {
      document.addEventListener('mousedown', handleClickOutside);
      return () => document.removeEventListener('mousedown', handleClickOutside);
    }
  }, [showFilters]);

  useEffect(() => {
    const savedTheme = localStorage.getItem('theme');
    if (savedTheme && ['light', 'dark', 'crimson-night', 'nature', 'ocean', 'sunset'].includes(savedTheme)) {
      document.documentElement.setAttribute('data-theme', savedTheme);
    } else {
      localStorage.setItem('theme', 'crimson-night');
      document.documentElement.setAttribute('data-theme', 'crimson-night');
    }
  }, []);

  useEffect(() => {
    const setViewHeight = () => {
      const vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty('--vh', `${vh}px`);
    };

    const updateNavHeight = () => {
      const navHeight = window.innerHeight - window.visualViewport.height + 60;
      document.documentElement.style.setProperty('--mobile-nav-height', `${navHeight}px`);
    };

    setViewHeight();
    updateNavHeight();

    window.addEventListener('resize', setViewHeight);
    window.visualViewport.addEventListener('resize', updateNavHeight);

    return () => {
      window.removeEventListener('resize', setViewHeight);
      window.visualViewport.removeEventListener('resize', updateNavHeight);
    };
  }, []);

  useEffect(() => {
    const handleResize = () => {
      setIsSidebarOpen(window.innerWidth >= 1440);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    if (hasNewNotification && location.pathname === '/profile') {
      setHasNewNotification(false);
    }
  }, [location.pathname, hasNewNotification]);

  useEffect(() => {
    let isMounted = true;
    let timeoutId;

    const updateLastActive = async () => {
      try {
        const response = await authenticatedApiCall('/update-last-active', 'POST');
        if (isMounted) {
          setHasNewNotification(response.new_notification);
        }
      } catch (error) {
        console.error('Error updating last active:', error);
      } finally {
        if (isMounted) {
          timeoutId = setTimeout(updateLastActive, 1850000);
        }
      }
    };

    if (isLoggedIn) {
      updateLastActive();
    }

    return () => {
      isMounted = false;
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
    };
  }, [isLoggedIn]);

  const getActiveFiltersCount = () => {
    if (!activeFilters) return 0;

    return Object.entries(activeFilters)
      .filter(([key]) => key !== 'sort')
      .reduce((total, [_, values]) =>
        Array.isArray(values) ? total + values.length : total, 0);
  };

  const NavItem = ({ to, icon: Icon, label, onClick, isButton = false, isBottom = false }) => {
    const isActive = isButton ? showFilters : location.pathname === to;
    const handleClick = (e) => {
      if (isButton) {
        e.preventDefault();
        onClick();
      }
    };

    const content = (
      <div className={`header-nav-item ${isActive ? 'active' : ''} ${isBottom ? 'bottom-nav-item' : ''}`} onClick={handleClick}>
        <div className="header-nav-item-container">
          <Icon size={isBottom ? 20 : 24} />
          {hasNewNotification && to === '/profile' && (
            <div className="header-nav-notification-dot"></div>
          )}
        </div>
        <span className="header-nav-label">{label}</span>
      </div>
    );

    return isButton ? content : <Link to={to}>{content}</Link>;
  };
  const user = JSON.parse(localStorage.getItem('user'));
  const username = user && user.username ? user.username : "";

  return (
    <>
      <button
        className={`header-sidebar-toggle ${isSidebarOpen ? 'open' : ''}`}
        onClick={() => setIsSidebarOpen(!isSidebarOpen)}
        aria-label="Toggle navigation"
      >
        {isSidebarOpen ? <X size={24} /> : <MenuIcon size={24} />}
      </button>

      <div
        className={`header-overlay ${isSidebarOpen ? 'visible' : ''}`}
        onClick={() => setIsSidebarOpen(false)}
      />

      <nav className={`header-sidebar ${isSidebarOpen ? 'open' : ''}`}>
        <div className="header-nav-container">
          {/* Top navigation items */}
          <div className="header-nav-items">
            {isLoggedIn ? (
              <NavItem to={`/profile/${username}`} icon={User} label="Profile" />
            ) : (
              <NavItem to="/login" icon={User} label="Login" />
            )}
            <NavItem to="/create" icon={BrainCircuit} label="Create" />
            <NavItem to="/design" icon={Brush} label="Designs" />
            {isDesignRoute && (
              <NavItem
                to="#"
                icon={Filter}
                label={
                  <>
                    Filter
                    {getActiveFiltersCount() > 0 && (
                      <span className='header-filter-count-span'>
                        active: {getActiveFiltersCount()}
                      </span>
                    )}
                  </>
                }
                onClick={() => setShowFilters(!showFilters)}
                isButton={true}
              />
            )}
          </div>

          {/* Bottom navigation items with smaller icons */}
          <div className="header-nav-items-bottom">
            <NavItem to="/" icon={FaInfo} label="About" isBottom={true} />
            <NavItem to="/docs" icon={FaQuestion} label="Questions" isBottom={true} />
          </div>
        </div>
      </nav>

      <div
        className={`header-filter-container ${showFilters ? 'visible' : ''}`}
        aria-label="Close filters"
      >
        <FilterComponent
          onFilterChange={onFilterChange}
          activeFilters={activeFilters}
          isVisible={showFilters}
        />
      </div>
    </>
  );
}

export default Header;