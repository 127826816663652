import React, { useEffect, useState } from 'react';
import './App.css';
import Header from './Components/Header';
import Home from './Components/Home';
import ScrollToTop from './Components/ScrollToTop';
import GoogleAnalytics from './Components/Analytics/GoogleAnalytics';
import { setCookie, getCookie } from './utils/cookies';
import ImageGenerator from './Components/Tools/ImageGenerator'
import SignIn from './Components/Auth/SignIn'
import PrivateRoute from './Components/Auth/PrivateRoute';
import { isImageGenerationLimited } from './utils/cookies';
import Profile from './Components/User/Profile';
import About from './Landing/About';
import PrivacyPolicy from './pages/PrivacyPolicy';
import TermsOfService from './pages/TermsOfService';
import Blog from './Components/Blog';
import AdminDashboard from './Components/Admin/AdminDashboard';
import AdminRoute from './Components/Auth/AdminRoute';
import { MoreOptionsProvider } from './Components/ImageCard';
import GithubCallBack from './Components/Auth/GithubCallBack';
import Unsubscribe from './Components/User/Unsubscribe';
import InstagramFollow from './Components/InstagramFollow';
import DesignViewerPage from './Components/Tools/Design/DesignViewerPage';
import CommunityChat from './Components/CommunityChat';
import ChatIcon from './Components/ChatIcon';

import {
  BrowserRouter as Router,
  Routes,
  Route
} from "react-router-dom";
import ImageDetailView from './Components/ImageDetailView';


function App() {

  const [activeFilters, setActiveFilters] = useState({
    sortBy: 'default',
    design_type: [],
    style: []
  });

  const handleFilterChange = (newFilters) => {
    setActiveFilters(newFilters);
  };

  useEffect(() => {
    const userSession = getCookie('user_session');

    if (!userSession) {
      const newSessionId = `session_${Math.random().toString(36).substring(2)}`;
      setCookie('user_session', newSessionId, 15);
    }
  }, []);

  return (
    <>
      <MoreOptionsProvider>
        <Router>
          <GoogleAnalytics />
          <ScrollToTop />
          <Header
            onFilterChange={handleFilterChange}
            activeFilters={activeFilters}
          />
          <Routes>
            <Route path="/" element={<About />} />
            <Route path="/login" element={<SignIn />} />

            <Route element={<PrivateRoute />}>
              {isImageGenerationLimited() && (
                <Route path='/create' element={<ImageGenerator />} />
              )}
              <Route path="/unsubscribe" element={<Unsubscribe />} />
            </Route>

            <Route element={<AdminRoute />}>
              <Route path="/admin" element={<AdminDashboard />} />
            </Route>

            {!isImageGenerationLimited() && (
              <Route path='/create' element={<ImageGenerator />} />
            )}
            <Route path="/design" element={
              <Home
                activeFilters={activeFilters}
                onFilterChange={handleFilterChange}
              />
            } />
            <Route path="/profile/:username" element={<Profile />} />
            <Route path="/design/:imageId" element={<ImageDetailView />} />
            <Route path="/design/:imageId/edit" element={<DesignViewerPage />} />
            <Route path="/privacy" element={<PrivacyPolicy />} />
            <Route path="/terms" element={<TermsOfService />} />
            <Route path="/docs" element={<Blog />} />
            <Route path="/github-callback" element={<GithubCallBack />} />
            <Route path="/follow" element={<InstagramFollow />} />
            <Route path="/community-chat" element={<CommunityChat />} />
          </Routes>
        </Router>
      </MoreOptionsProvider>
      <ChatIcon />
    </>
  );
}

export default App;