import React, { useState, useEffect, useRef, useMemo, memo } from 'react';
import ReactMarkdown from 'react-markdown';
import { authenticatedApiCall } from '../utils/authenticatedApiCall';
import { formatTimestamp } from '../utils/dateFormatter';
import Pusher from 'pusher-js';
import { v4 as uuidv4 } from 'uuid';
import '../css/CommunityChat.css';
import { isLoggedIn } from '../utils/cookies';
import { Bot, Info } from 'lucide-react';
import { useDocumentHead } from '../hooks/useDocumentHead';

const BotIcon = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="bot-icon">
        <path d="M12 8V4H8" />
        <rect width="16" height="10" x="4" y="12" rx="2" />
        <path d="M2 14h2" />
        <path d="M20 14h2" />
        <path d="M15 13v2" />
        <path d="M9 13v2" />
    </svg>
);

const ChatInstructions = () => (
    <div className="gcc-chat-instructions">
        <p><strong>Quick Tips:</strong></p>
        <ul>
            <li>To chat with Raya, start your message with <code>/</code></li>
            <li>To share an image, paste its markdown link</li>
        </ul>
    </div>
);

const MemoizedImageWithLoader = memo(({ src, alt }) => {
    const [isLoading, setIsLoading] = useState(true);
    const [hasError, setHasError] = useState(false);

    const handleImageLoad = () => {
        setIsLoading(false);
    };

    const handleImageError = () => {
        setIsLoading(false);
        setHasError(true);
    };

    return (
        <div className="gcc-image-container">
            {isLoading && !hasError && (
                <div className="gcc-image-loader  animate-spin">
                    <div className="gcc-spinner"></div>
                </div>
            )}
            {hasError ? (
                <div className="gcc-image-error">
                    Image failed to load
                </div>
            ) : (
                <img
                    src={src}
                    alt={alt || 'Image'}
                    onLoad={handleImageLoad}
                    onError={handleImageError}
                    className="gcc-loaded-image"
                    style={isLoading ? { visibility: 'hidden' } : {}}
                    referrerPolicy="no-referrer"
                />
            )}
        </div>
    );
}, (prevProps, nextProps) => prevProps.src === nextProps.src);

const COLORS = [
    '#FF6F61', '#B5651D', '#6A5ACD', '#228B22',
    '#4682B4', '#8A2BE2', '#DAA520', '#2F4F4F'
];

const CommunityChat = ({ shouldBroadcastJoin = false }) => {
    const [messages, setMessages] = useState([]);
    const [newMessage, setNewMessage] = useState('');
    const [user, setUser] = useState(null);
    const [usernameColors, setUsernameColors] = useState({});
    const [joinNotification, setJoinNotification] = useState(null);
    const pusherRef = useRef(null);
    const messagesEndRef = useRef(null);
    const sentMessagesRef = useRef(new Set());
    const lastJoinTimeRef = useRef(0);
    const [isBotCommand, setIsBotCommand] = useState(false);

    const markdownComponents = useMemo(() => ({
        img: ({ node, ...props }) => {
            const src = props.src || '';
            return <MemoizedImageWithLoader src={src} alt={props.alt} />;
        },
    }), []);

    // Memoized color generation for usernames
    const getUserColor = useMemo(() => {
        return (username) => {
            if (!usernameColors[username]) {
                const color = COLORS[
                    Math.floor(Math.random() * COLORS.length)
                ];
                setUsernameColors(prev => ({ ...prev, [username]: color }));
            }
            return usernameColors[username];
        };
    }, [usernameColors]);

    const validateMessageLength = (message) => {
        const markdownLinkRegex = /!\[.*?\]\((.*?)\)/g;
        const links = message.match(markdownLinkRegex);

        if (links) {
            return message.length <= 5500 &&
                links.every(link => link.length <= 5000);
        }

        return message.length <= 500;
    };

    useDocumentHead(
        'Community Chat | Rangrez AI',
        'Join the vibrant community conversation, connect with fellow creators, and chat with Raya AI assistant.',
        'index, follow'
    );

    useEffect(() => {
        const fetchUserProfile = async () => {
            try {
                const userData = await authenticatedApiCall('/get-chat-profile-details');
                setUser(userData);

                // Initialize Pusher
                const pusher = new Pusher(process.env.REACT_APP_PUSHER_KEY, {
                    cluster: process.env.REACT_APP_PUSHER_CLUSTER,
                    encrypted: true
                });

                const channel = pusher.subscribe('community-chat');

                // Bind to new message event
                channel.bind('new-message', (data) => {
                    if (data.clientMessageId) {
                        setMessages(prevMessages => {
                            // Comprehensive timestamp normalization
                            let normalizedTimestamp;
                            try {
                                // Ensure the timestamp is parsed correctly regardless of format
                                const parsedDate = new Date(data.timestamp);
                                normalizedTimestamp = parsedDate.toISOString();
                            } catch (error) {
                                console.error('Failed to parse timestamp:', data.timestamp);
                                normalizedTimestamp = new Date().toISOString(); // Fallback to current time
                            }
                            // Filter out existing messages with the same clientMessageId
                            const filteredMessages = prevMessages.filter(
                                msg => msg.clientMessageId !== data.clientMessageId
                            );

                            // Add messages - first user message, then bot message if exists
                            const newMessages = [
                                {
                                    username: data.username,
                                    message: data.message,
                                    timestamp: normalizedTimestamp,
                                    clientMessageId: data.clientMessageId
                                }
                            ];

                            // If bot message exists, add it
                            if (data.botUsername && data.botMessage) {
                                newMessages.push({
                                    username: data.botUsername,
                                    message: data.botMessage,
                                    timestamp: normalizedTimestamp,
                                    clientMessageId: data.clientMessageId
                                });
                            }

                            return [
                                ...filteredMessages,
                                ...newMessages
                            ];
                        });
                    }
                });

                // Bind to user join event
                channel.bind('user-joined', (data) => {
                    const currentTime = Date.now();
                    const isLoggedInUser = user && data.username === user.username;

                    // Check if 30 minutes have passed since last join for this user
                    if (!isLoggedInUser ||
                        (isLoggedInUser && currentTime - lastJoinTimeRef.current > 30 * 60 * 1000)) {

                        // Update last join time
                        lastJoinTimeRef.current = currentTime;

                        // Set join notification
                        setJoinNotification({
                            message: data.username === "guest user"
                                ? `A guest is viewing the conversation`
                                : `${data.username} joined the conversation`,
                            timestamp: currentTime
                        });

                        // Clear notification after 3 seconds
                        setTimeout(() => {
                            setJoinNotification(null);
                        }, 10000);
                    }
                });

                pusherRef.current = pusher;

                // Fetch initial messages
                const fetchedMessages = await authenticatedApiCall('/community-messages');
                setMessages(fetchedMessages);

                // Broadcast user joining
                console.log("shouldBroadcastJoin", shouldBroadcastJoin)
                if (shouldBroadcastJoin) {
                    await authenticatedApiCall('/user-joined', 'POST');
                }
            } catch (error) {
                console.error('Error setting up chat:', error);
            }
        };

        fetchUserProfile();

        return () => {
            // Cleanup Pusher subscription
            if (pusherRef.current) {
                pusherRef.current.unsubscribe('community-chat');
            }
        };
        // eslint-disable-next-line
    }, [shouldBroadcastJoin]);

    useEffect(() => {
        messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
    }, [messages]);

    const sendMessage = async (e) => {
        e.preventDefault();
        if (!newMessage.trim() || !user || !isLoggedIn()) return;

        if (!validateMessageLength(newMessage)) {
            alert('Message is too long. Max 500 characters for text, or 3000 for messages with markdown links.');
            return;
        }

        const clientMessageId = uuidv4();

        if (newMessage.startsWith('/')) {
            const botMessage = newMessage.slice(1).trim();

            const optimisticUserMessage = {
                username: user.username,
                message: newMessage,
                timestamp: { $date: new Date().toISOString() },
                clientMessageId
            };

            const optimisticBotMessage = {
                username: 'Raya',
                message: 'Thinking...',
                timestamp: { $date: new Date().toISOString() },
                clientMessageId
            };

            // Immediately add both messages to the chat
            setMessages(prevMessages => [
                ...prevMessages,
                optimisticUserMessage,
                optimisticBotMessage
            ]);

            // Clear the input
            setNewMessage('');

            try {
                // Send bot message request
                await authenticatedApiCall('/bot-message', 'POST', {
                    message: botMessage,
                    clientMessageId
                });

                // Note: The bot's actual response will be handled by Pusher event
            } catch (error) {
                console.error('Bot message error:', error);

                // If there's an error, remove both optimistic messages
                setMessages(prevMessages =>
                    prevMessages.filter(msg =>
                        msg.clientMessageId !== clientMessageId
                    )
                );
            }
            return;
        }

        const optimisticMessage = {
            username: user.username,
            message: newMessage,
            timestamp: new Date().toISOString(),
            clientMessageId
        };

        setMessages(prevMessages => [...prevMessages, optimisticMessage]);
        sentMessagesRef.current.add(clientMessageId);

        setNewMessage('');

        try {
            await authenticatedApiCall('/send-message', 'POST', {
                message: newMessage,
                clientMessageId
            });
        } catch (error) {
            setMessages(prevMessages =>
                prevMessages.filter(msg => msg !== optimisticMessage)
            );
            sentMessagesRef.current.delete(clientMessageId);
            setNewMessage(newMessage);
        }
    };

    const handleMessageChange = (e) => {
        const message = e.target.value;
        setNewMessage(message);

        setIsBotCommand(message.startsWith('/'));
    };

    const handleKeyDown = (e) => {
        // Shift + Enter creates a new line
        if (e.key === 'Enter' && e.shiftKey) {
            return;
        }
        // Enter without Shift submits the form
        if (e.key === 'Enter' && !e.shiftKey) {
            e.preventDefault();
            sendMessage(e);
        }
    };

    const renderMessage = (msg, index) => {
        const usernameColor = getUserColor(msg.username);
        const displayUsername = user && msg.username === user.username
            ? 'You'
            : msg.username === 'Raya'
                ? <>{msg.username} <BotIcon /></>
                : msg.username;

        return (
            <div key={index} className="gcc-message">
                <div className="gcc-message-header">
                    <strong
                        className="gcc-message-username"
                        style={{ color: usernameColor }}
                    >
                        {displayUsername}
                    </strong>
                    <span className="gcc-message-timestamp">
                        {formatTimestamp(msg.timestamp)}
                    </span>
                </div>
                <ReactMarkdown
                    components={markdownComponents}
                >
                    {msg.message}
                </ReactMarkdown>
            </div>
        );
    };

    if (!user) {
        return (
            <div className="gcc-container">
                <h1 className='gcc-heading'>Live Community Chat</h1>
                <div className="gcc-loading">Loading...</div>
            </div>
        );
    }

    return (
        <>
            <div className="gcc-community-chat">
                {joinNotification && (
                    <div className="gcc-join-notification">
                        {joinNotification.message}
                    </div>
                )}
                <div className="gcc-messages-container">
                    {messages.map(renderMessage)}
                    <div ref={messagesEndRef} />
                </div>
                <form onSubmit={sendMessage} className="gcc-message-form">
                    <div className="gcc-input-container">
                        <textarea
                            type="text"
                            value={newMessage}
                            onChange={handleMessageChange}
                            onKeyDown={handleKeyDown}
                            placeholder={isLoggedIn()
                                ? "Type a message or '/' in start to chat with Raya."
                                : "Log in to join the conversation."}
                            className={`gcc-message-input ${isBotCommand ? 'gcc-bot-command' : ''}`}
                            disabled={!isLoggedIn()} />
                        {isBotCommand && (
                            <div className="gcc-bot-command-label">
                                <Bot size={16} /> Chatting with Raya
                            </div>
                        )}
                        <div className="gcc-input-info">
                            <div className="gcc-info-icon">
                                <Info size={18} />
                                <div className="gcc-tooltip">
                                    <ChatInstructions />
                                </div>
                            </div>
                        </div>
                    </div>
                    <button
                        type="submit"
                        className="gcc-send-button"
                        disabled={!newMessage.trim() || !isLoggedIn()}
                    >
                        Send
                    </button>
                </form>
            </div></>
    );
};

export default CommunityChat;