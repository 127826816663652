import React from 'react';
import '../css/PrivacyPolicy.css';
import { useDocumentHead } from '../hooks/useDocumentHead';

const PrivacyPolicy = () => {

    useDocumentHead(
        'Privacy Policy - Rangrez AI | Your Data Security Matters',
        'Read the privacy policy for Rangrez AI to understand how we collect, use, and protect your personal information. Your privacy and data security are our top priorities.'
    );    

    return (
        <div className="pp-container margin">
            <h1 className="pp-title">Privacy Policy</h1>
            <div className="pp-content">
                <section className="pp-section">
                    <h2 className="pp-section-title">1. Information We Collect</h2>
                    <p className="pp-text">We collect the following information from users of our services:</p>
                    <ul className="pp-list">
                        <li>Content you create and save using our AI tools</li>
                        <li>Your Google account public information (name, email, profile picture) when signing up with Google</li>
                        <li>Any other information you choose to provide, including feedback or inquiries</li>
                    </ul>
                </section>
                <section className="pp-section">
                    <h2 className="pp-section-title">2. How We Use Your Information</h2>
                    <p className="pp-text">The information we collect is used to:</p>
                    <ul className="pp-list">
                        <li>Provide, maintain, and improve our AI tools and services</li>
                        <li>Respond to your inquiries and feedback</li>
                        <li>Analyze usage trends and improve our platform's performance</li>
                    </ul>
                </section>
                <section className="pp-section">
                    <h2 className="pp-section-title">3. Sharing of Information</h2>
                    <p className="pp-text">We do not share your personal information with any third-party service providers or partners. We may share information in the following situations:</p>
                    <ul className="pp-list">
                        <li>To comply with legal obligations or respond to legal processes</li>
                        <li>To protect the rights, property, or safety of rangrezai.com, our users, or others</li>
                    </ul>
                </section>
                <section className="pp-section">
                    <h2 className="pp-section-title">4. Data Retention</h2>
                    <p className="pp-text">We retain your personal information only for as long as necessary to fulfill the purposes for which it was collected, including for the purposes of satisfying any legal, accounting, or reporting requirements.</p>
                </section>
                <section className="pp-section">
                    <h2 className="pp-section-title">5. Security</h2>
                    <p className="pp-text">We implement a variety of security measures to ensure the safety of your personal information, including encryption and secure servers. However, please be aware that no method of transmission over the internet is 100% secure.</p>
                </section>
                <section className="pp-section">
                    <h2 className="pp-section-title">6. Your Rights</h2>
                    <p className="pp-text">You have the right to access, correct, and delete your personal information. If you'd like to exercise these rights, please contact us at <a href="mailto:hello@rangrezai.com">hello@rangrezai.com</a>.</p>
                </section>
                <section className="pp-section">
                    <h2 className="pp-section-title">7. Changes to this Privacy Policy</h2>
                    <p className="pp-text">We may update this privacy policy from time to time. If we make any significant changes, we will notify you by revising the date at the top of the policy or through other appropriate communication channels.</p>
                </section>
                <section className="pp-section">
                    <h2 className="pp-section-title">8. Contact Us</h2>
                    <p className="pp-text">If you have any questions or concerns regarding our privacy practices, please contact us at <a href="mailto:hello@rangrezai.com">hello@rangrezai.com</a></p>
                </section>
            </div>
        </div>
    );
};

export default PrivacyPolicy;
